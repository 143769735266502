import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
//import Button from "react-bootstrap/Button";
import ".././App.css";

export function ProgramTitle() {
  return (
    <div level={3}>
      <div className="WrappedContent">
        <Container fluid className="AppContainerContent">
            {/***PROGRAM INFO***/}
            <Row className="eventDate">
              <Col className="ProgramInfo">
                <div>
                  <h4>Animation Nights New York </h4>
                  <p>presents</p>
                  {/* */}
                  <h1>PIECED TOGETHER</h1>
                  
                  <h4>Program 99</h4>
                  <p>Season 10</p>
                </div>    
              </Col>
            </Row>
            {/*** PROGRAM INFO 
            <Row>
              <Col>
                <div className="ButtonContent">
                  <Button
                    href="https://www.animationnights.com/screening73/"
                    target="_blank"
                    variant="outline-primary"
                    size="lg"
                  >
                    PROGRAM INFO
                  </Button>
                </div>
              </Col>
            </Row>
           ***/} 
        </Container>
      </div>
    </div>
  );
}
