//import React from 'react';
import React,{Component} from "react";
//import ReactDOM from "react-dom";
import '../AppCast.css';
//import awsconfig from './aws-exports';
//import Button from 'react-bootstrap/Button';


class CastNCarry extends Component {
  
  componentDidMount() {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://player.dacast.com/js/player.js?contentId=2a264a851107c835d05d5f21d387fcdd-playlist-e6e1a52e-1e1c-496f-9271-d72cf04ae8d0";
    this.div.appendChild(script);
  }
  
  render() {
    return (
      <div className="CastNCarry" ref={el => (this.div = el)}>
        <div className="CastWrapper">
          
          <script id="2a264a851107c835d05d5f21d387fcdd-playlist-e6e1a52e-1e1c-496f-9271-d72cf04ae8d0" 
            width="100%"
            //height="100%"
            //width="640"
            //height="360"
            //width="1280" 
            height="720" 
            //width="1024" 
            //height="576" 
            src="https://player.dacast.com/js/player.js?contentId=2a264a851107c835d05d5f21d387fcdd-playlist-e6e1a52e-1e1c-496f-9271-d72cf04ae8d0"  
            class="cnc-saas-vid">
          </script>
          
          {/*
          <script id="151011_l_571400" 
            //width="100%"
            //height="100%"
            //width="1280" 
            //height="720" 
            width="640"
            height="360"
            src="https://player.castncarry.com/js/player.js?contentId=151011_l_571400"  
            class="cnc-saas-vid">
          </script>
          */}
          
        </div>  
      </div>
    );
  }
}


export default CastNCarry;





