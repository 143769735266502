import React from "react";
import Row from "react-bootstrap/Row";
import Media from "react-bootstrap/Media";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ".././App.css";

export function NextEventDateInfo() {
  return (
    <div level={3}>
      <div className="WrappedContent">
        <Container fluid className="AppContainerContent">
            {/*** NEXT EVENT DATE ***/}
            <Row className="eventDate">
              <Col className="ProgramInfo">
                <Media>
                  <Media.Body>
                    <p>Next Online Event:</p>
                    <h2>Sunday October 20, 2024</h2> 
                    <p>Films will be available for screening 11am-11pm Eastern Time (UTC-04:00)</p>
                  </Media.Body>
                </Media>
              </Col>
            </Row>
            {/*** POSTER ***/}
            <Row className="MediaRow">
              <Col className="MediaContainer">
                <div className="MediaWrapper">
                  <Media>
                    <div className="PosterImage">
                    <img 
                      width="100%"
                      height="100%"
                      loading="lazy"
                      className="ANNY Virtual Events"
                      src="https://s3.amazonaws.com/animationnights.media/images/filmStills/99_ANNYFBCard.jpg"
                      //src = "https://s3.amazonaws.com/animationnights.media/images/NewLogo.jpg"
                      alt="ANNY Virtual Events"
                    />
                    </div>
                  </Media>  
                </div>
              </Col>
            </Row>
        </Container>
      </div>
    </div>
  );
}
